/* Grids */
.grid {
  display: grid;
  width: 100%;
  height: 100%;
}

.grid > * {
  margin: 2rem;
}

/* Grid Sizes */
.grid-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
