/* Grid Columns Sizes */
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

/* Grid Col Start */

.grid-col-start-1 {
  grid-column: 1;
}

.grid-col-start-2 {
  grid-column: 2;
}

.grid-col-start-3 {
  grid-column: 3;
}

.grid-col-start-4 {
  grid-column: 4;
}
