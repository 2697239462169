@keyframes easeInFade {
  from {
    opacity: 0%;
    left: -1.5rem;
  }
  to {
    opacity: 100%;
    left: 0%;
  }
}

.card {
  position: relative;
  width: 100%;
  animation: easeInFade 1s 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  color: black;
}

.cardBody {
  display: inline-block;
  display: flex;
  min-height: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.cardTitle {
  display: block;
  font-weight: bold;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem;
}
