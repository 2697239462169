.padding-8px {
  padding: 8px;
}

.padding-4px {
  padding: 4px;
}

.padding-2px {
  padding: 2px;
}
