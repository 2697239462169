.width-full {
  width: 100%;
}

.width-640px {
  width: 640px;
}

.width-512px {
  width: 512px;
}

.width-448px {
  width: 448px;
}

.width-384px {
  width: 384px;
}

.width-256px {
  width: 256px;
}

.width-128px {
  width: 128px;
}

.width-64px {
  width: 64px;
}
