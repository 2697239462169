.typewriter {
  display: inline-block;
}

.typewriter > * {
  overflow: hidden;
  border-right: 0.15em solid orange;
  white-space: nowrap;
  animation: typing 1s steps(20, end) forwards;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
    border-color: transparent;
  }
}
